import { createGlobalStyle } from 'styled-components'

import { colors } from 'bl-common/src/constants/colors'

import { DINPro } from './fonts'

export const GlobalThemeVariables = createGlobalStyle`
  :root {
    --reach-dialog: 1;

    // Typography
    --font-family: ${DINPro.style.fontFamily};
    --font-weight-light: 300;
    --font-weight-normal: 400;
    --font-weight-medium: 500;
    --font-weight-bold: 600;
    --line-height: 1.5;
    --line-height-heading: 1.15;

    // Colors
    --color-accent: ${colors.deepBlue};
    --color-secondary: ${colors.deepBlue};
    --color-success: #169222;
    --color-info: ${colors.deepBlue};
    --color-warning: ${colors.orange};
    --color-error: ${colors.errorRed};
    --color-fillPrimary: ${colors.white};
    --color-background: ${colors.backgroundWhite};

    // Common colored elements
    --color-text: ${colors.midGrey};
    --color-link: ${colors.deepBlue};
    --color-listMarker: ${colors.deepBlue};
    --color-scrollbar-thumb: ${colors.deepBlue};
    --color-scrollbar-track: ${colors.lightGrey};

    // Common element values
    // border-radius shouldn't need to be set but it's here for reference
    --border-radius: 0;

    // Spinner colors
    --color-spinner-car: #dee9ef;
    --color-spinner-circle: #50a7ba;
  }

  .t-dark {
    --color-background: ${colors.darkmode};
    --color-text: ${colors.white};
    --color-fillPrimary: ${colors.darkmodeOffset};
    --color-accent: ${colors.blueOnDark};
    --color-info: ${colors.deepBlue};
    --color-link: ${colors.blueOnDark};
    --color-listMarker: ${colors.blueOnDark};

    --color-scrollbar-thumb: ${colors.blueOnDark};
    --color-scrollbar-track: ${colors.darkmodeOffset};
  }
`
