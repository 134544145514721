import { useTranslation } from 'react-i18next'
import { motion } from 'framer-motion'
import styled, { css } from 'styled-components'

import { colors } from '../constants/colors'
import { Spinner } from '../elements/Spinner'
import { Type } from '../elements/Typography/Typography'
import { PartialBookingEngine } from '../styles/types'
import { media } from '../utils/media'
import { Input } from './Input/Input'

type ButtonType = 'button' | 'reset' | 'submit'

type TextProps = {
  isHidden?: boolean
}

const InputWrap = styled.div`
  display: grid;
  grid-template-columns: 1fr max-content;
`

const InputButton = styled.button<{
  themeStyle?: PartialBookingEngine['inputTextField']['button']
}>`
  position: relative;
  align-items: center;
  background: ${props =>
    props.themeStyle?.background ??
    'linear-gradient(90deg, #6793ae 0%, #50a7ba 97.99%), #d8d8d8'};
  color: ${props => props.themeStyle?.textColor ?? colors.white};
  cursor: pointer;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
  border-bottom-right-radius: ${props =>
    `${props.themeStyle?.borderRadius ?? 0}px`};
  border-top-right-radius: ${props =>
    `${props.themeStyle?.borderRadius ?? 0}px`};
  padding: ${({ theme }) => `0 ${theme.spacing[1.5]}`};

  :disabled {
    opacity: 0.9;
    cursor: default;
  }
`
const TickWrapper = styled.svg`
  height: ${({ theme }) => theme.spacing[2]};
  width: ${({ theme }) => theme.spacing[2]};

  ${media.bmd(css`
    height: ${({ theme }) => theme.spacing[1.5]};
    width: ${({ theme }) => theme.spacing[1.5]};
  `)}
`
const Text = styled(Type)<TextProps>`
  line-height: 1;
  padding-bottom: 3px;

  ${({ isHidden }) =>
    isHidden &&
    css`
      opacity: 0;
      visability: hidden;
    `}
`

const IconWrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`

const variants = {
  inactive: { pathLength: 0 },
  active: { pathLength: 1 },
}

const Tick = ({ active }) => (
  <IconWrapper>
    <TickWrapper
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <motion.path
        d="M1 6L6 12L15 1"
        stroke="white"
        strokeWidth="2"
        initial={{ pathLength: 0 }}
        variants={variants}
        animate={active ? 'active' : 'inactive'}
        exit={{}}
        transition={{ duration: 0.3 }}
      />
    </TickWrapper>
  </IconWrapper>
)

export const ButtonInput = ({
  id,
  name,
  value = '',
  label = undefined,
  placeholder = undefined,
  type = 'text',
  required = false,
  isRequired = false,
  hasError = false,
  disableEditing = false,
  onChange = undefined,
  onFocus = undefined,
  loading = false,
  disabled = false,
  success = false,
  onButtonClick = undefined,
  buttonType = 'submit' as ButtonType,
  buttonLabel = undefined,
  inputThemeStyle = undefined as PartialBookingEngine['inputTextField'],
}: {
  id: string
  name: string
  value?: string
  label?: string
  placeholder?: string
  type?: string
  required?: boolean
  isRequired?: boolean
  hasError?: boolean
  disableEditing?: boolean
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void
  loading?: boolean
  disabled?: boolean
  success?: boolean
  onButtonClick?: () => void
  buttonType?: ButtonType
  buttonLabel?: string
  inputThemeStyle?: PartialBookingEngine['inputTextField']
}) => {
  const disableButton = loading || disabled
  const { t } = useTranslation('booking')

  return (
    <InputWrap>
      <Input
        id={id}
        name={name}
        value={value}
        label={label}
        placeholder={placeholder}
        type={type}
        required={required}
        isRequired={isRequired}
        hasError={hasError}
        disableEditing={disableEditing}
        onChange={onChange || (() => {})}
        onFocus={onFocus || (() => {})}
        themeStyle={inputThemeStyle?.input}
        disableBorderRadiusRightSide
      />
      <InputButton
        type={buttonType}
        aria-label="submit"
        disabled={disableButton}
        onClick={onButtonClick}
        data-cy={`${name}-submit-button`}
        themeStyle={inputThemeStyle?.button}
      >
        <Text
          preset="text"
          weight="bold"
          size={{ xs: 14, md: 16 }}
          color={colors.white}
          isHidden={loading || success}
        >
          {buttonLabel || t('booking.general.add')}
        </Text>
        {success ? (
          <Tick active={success} />
        ) : (
          loading && (
            <IconWrapper>
              <Spinner
                shouldAnimate
                radius={15}
                color={inputThemeStyle?.button?.spinnerColor}
              />
            </IconWrapper>
          )
        )}
      </InputButton>
    </InputWrap>
  )
}
